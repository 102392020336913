<template>
  <v-card :loading="loading">
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}
    </v-snackbar>
    <v-btn style="right: 10px; top: 10px" icon color="primary" fab small absolute @click="$emit('cancel')">
      <v-icon dark>fa-times</v-icon>
    </v-btn>
    <v-card-title class="pa-2">
      <span class="headline">Modificar Orden
        <v-chip :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'
          " style="display: inline-block" dark class="d-none md-block" v-if="editedItem.shortId"
          :text-color="$vuetify.theme.dark ? 'white' : 'black'" small>
          {{ editedItem.shortId }}
        </v-chip>
      </span>

      <v-card-text style="height: auto; overflow-x: hidden">
        <v-row justify="start" align="center" class="mx-0 pa-0">
          <v-chip :color="getColor(editedItem.progressCurrentStatus)" small dark
            style="text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2)">
            {{ editedItem.progressCurrentStatus | statusFilter }}
          </v-chip>
          <div class="grey--text">
            {{ editedItem.progressPlacedAtRelative }}
          </div>
          <div class="mt-2 d-block" style="width: 100%">
            Ordenada de
            <b>{{
              editedItem.orderType == "errand" ||
                editedItem.orderType == "cargo"
                ? "MANDADITOS"
                : editedItem.businessName
            }}</b>
            por
            <b>{{ editedItem.userName }}</b>
          </div>
          <div v-if="editedItem.coupon && editedItem.coupon.source" class="d-block" style="width: 100%">
            Cupón:
            <b>
              {{ editedItem.coupon.code }}
              <b style="color: green">
                {{ calculateCouponDiscount | currency }}
                de descuento
              </b>
              aplicado al {{ editedItem.coupon.type | filterCouponType }}.</b>
          </div>
        </v-row>
      </v-card-text>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text style="height: auto; overflow-x: hidden">
      <v-row class="my-2" :class="{
        'd-flex': true,
        'justify-center': true,
        'mx-5 mt-3': $vuetify.breakpoint.xs,
      }">
        <v-col cols="12" md="6" class="pa-1">
          <v-text-field v-model="businessInvoiceNumber" label="Numero de Factura" placeholder="# factura del comercio"
            rounded reverse :background-color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'
              " hide-details filled></v-text-field>
        </v-col>

        <v-col cols="12" md="6" class="pa-1">
          <v-text-field v-model.number="editedItem.productsSubTotal" type="number" suffix="HNL"
            label="Subtotal de Productos" rounded reverse :background-color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'
              " hide-details filled></v-text-field>
        </v-col>

        <v-col cols="12" md="6" class="pa-1">
          <v-text-field v-model.number="calculatedExtraFeesCharge" readonly type="number" suffix="HNL"
            label="Cargos por Servicio" :background-color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)'
              " rounded reverse hide-details filled></v-text-field>
        </v-col>

        <v-col cols="12" md="6" class="pa-1">
          <v-text-field v-model.number="calculatedOrderSubTotal" readonly type="number" suffix="HNL"
            label="SubTotal de la Orden" :background-color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)'
              " rounded reverse hide-details filled></v-text-field>
        </v-col>

        <v-col cols="12" md="6" class="pa-1">
          <v-text-field v-model.number="editedItem.deliveryCost" type="number" suffix="HNL" label="Costo por Delivery"
            :background-color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'
              " rounded reverse hide-details filled></v-text-field>
        </v-col>

        <v-col cols="12" md="6" class="pa-1">
          <v-text-field v-model.number="editedItem.orderTip" type="number" suffix="HNL" label="Propina"
            :background-color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'
              " rounded reverse hide-details filled></v-text-field>
        </v-col>

        <v-col v-if="editedItem.badWeatherFee && editedItem.isRaining" cols="12" md="6" class="pa-1">
          <v-text-field v-model.number="editedItem.badWeatherFee" type="number" suffix="HNL" readonly
            label="Cargo por lluvia" :background-color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'
              " rounded reverse hide-details filled></v-text-field>
        </v-col>

        <v-col cols="12" md="6" class="pa-1">
          <v-text-field v-model.number="calculatedOrderTotal" readonly suffix="HNL" type="number"
            label="Total de la orden" :background-color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)'
              " rounded reverse hide-details filled></v-text-field>
        </v-col>

        <v-col v-if="editedItem.coupon && editedItem.coupon.source" cols="12" sm="12" md="12">
          <p class="mb-0 pb-0 font-weight-bold mt-5">Descuento por cupón</p>
          <v-divider></v-divider>
        </v-col>

        <v-col v-if="editedItem.coupon && editedItem.coupon.source" cols="12" md="5" class="pa-1">
          <v-text-field v-model.number="calculateCouponDiscount" readonly disabled type="number" suffix="HNL"
            label="Descuento por cupón" :background-color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)'
              " rounded reverse hide-details filled></v-text-field>
        </v-col>

        <v-col v-if="editedItem.coupon && editedItem.coupon.source" cols="12" md="7" class="pa-1">
          <v-text-field :value="calculatedOrderTotal - calculateCouponDiscount" readonly suffix="HNL" type="number"
            label="Total de la orden con descuento aplicado" :background-color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)'
              " rounded reverse hide-details filled></v-text-field>
        </v-col>

        <!-- <v-col class="mb-0 pb-0" cols="12" sm="12" md="12">
          <v-divider></v-divider>
        </v-col> -->

        <v-col cols="12" sm="12" md="12" class="pa-1 mt-5">
          <v-textarea hide-details placeholder="¿Motivo de modificación de la orden?" rounded
            v-model="modificationReason" filled rows="3" maxlength="200" />
        </v-col>
        <span style="cursor: pointer; color: #FF1744" class="mt-5 font-weight-bold"
          @click="dialogModificationHistoy = true" v-if="editedItem.modificationReasons">Ver historial de
          modificaciones</span>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row align="center" class="my-2 mx-4">
        <v-btn color="primary" rounded v-if="['sudo', 'admin', 'AF'].includes(user.type)" block large :elevation="0"
          :minHeight="56" :loading="loading" :disabled="loading" @click="save">
          Guardar Cambios
        </v-btn>
      </v-row>
    </v-card-actions>

    <v-dialog scrollable persistent v-if="dialogModificationHistoy" v-model="dialogModificationHistoy"
      max-width="900px">
      <order-modification-history @cancel="dialogModificationHistoy = false" :editedItem="item" />
    </v-dialog>
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapState } from "vuex";
import orderModificationHistory from "./order-modification-history";

export default {
  name: "edit-order",
  props: ["item", "searchableTags", "filteredLogs"],
  components: {
    orderModificationHistory,
  },
  data() {
    return {
      loading: false,
      snackbarText: "",
      snackbar: false,
      editedItem: {},
      modificationReason: "",
      dialogModificationHistoy: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    calculatedExtraFeesCharge: {
      get: function () {
        if (this.editedItem.extraFees && this.editedItem.extraFees.length) {
          let extraFeesCharge = 0;
          for (let i = 0; i < this.editedItem.extraFees.length; i++) {
            const fee = this.editedItem.extraFees[i];
            if (fee.active) {
              let total = fee.extraFeeIsFixed
                ? fee.extraFeeSum
                : (this.editedItem.productsSubTotal * fee.extraFeeSum) / 100;
              extraFeesCharge = extraFeesCharge + total;
            }
          }
          return Math.round((extraFeesCharge + Number.EPSILON) * 100) / 100;
        } else {
          return 0;
        }
      },
      set: function (newValue) {
        this.editedItem.extraFeesCharge = newValue;
      },
    },

    // calculatedOriginalOrderTotal: {
    //   get: function () {
    //     return (
    //       Math.round(
    //         (this.calculatedOrderSubTotal +
    //           this.editedItem.deliveryCost +
    //           this.editedItem.orderTip +
    //           // -this.calculateCouponDiscount +
    //           Number.EPSILON) *
    //           100
    //       ) / 100
    //     );
    //   },
    //   set: function (newValue) {
    //     this.editedItem.orderTotal = newValue;
    //   },
    // },

    calculatedOrderTotal: {
      get: function () {
        return (
          Math.round(
            (this.calculatedOrderSubTotal +
              this.editedItem.deliveryCost +
              this.editedItem.orderTip +
              // (this.editedItem.isRaining ? this.editedItem.badWeatherFee : 0) +
              // -this.calculateCouponDiscount +
              Number.EPSILON) *
            100
          ) / 100
        );
      },
      set: function (newValue) {
        this.editedItem.orderTotal = newValue;
      },
    },
    calculatedOrderSubTotal: {
      get: function () {
        return (
          Math.round(
            (this.calculatedExtraFeesCharge +
              this.editedItem.productsSubTotal +
              Number.EPSILON) *
            100
          ) / 100
        );
      },
      set: function (newValue) {
        this.editedItem.orderSubTotal = newValue;
      },
    },
    calculateCouponDiscount() {
      if (this.editedItem.coupon && this.editedItem.coupon.type) {
        let discount = 0;

        switch (this.editedItem.coupon.type) {
          case "subtotal":
            if (this.editedItem.coupon.isFixed) {
              discount =
                this.editedItem.coupon.amount > this.editedItem.productsSubTotal
                  ? this.editedItem.productsSubTotal
                  : this.editedItem.coupon.amount;
            } else {
              discount =
                this.editedItem.productsSubTotal *
                (this.editedItem.coupon.amount / 100);

              discount =
                this.editedItem.coupon.maximumDiscount &&
                  discount > this.editedItem.coupon.maximumDiscount
                  ? this.editedItem.coupon.maximumDiscount
                  : discount;
            }

            break;
          case "total":
            if (this.editedItem.coupon.isFixed) {
              discount =
                this.editedItem.coupon.amount > this.calculatedOrderTotal
                  ? this.calculatedOrderTotal
                  : this.editedItem.coupon.amount;
            } else {
              discount =
                this.calculatedOrderTotal *
                (this.editedItem.coupon.amount / 100);

              discount =
                this.editedItem.coupon.maximumDiscount &&
                  discount > this.editedItem.coupon.maximumDiscount
                  ? this.editedItem.coupon.maximumDiscount
                  : discount;
            }

            break;

          case "shipping":
            if (this.editedItem.coupon.isFixed) {
              discount =
                this.editedItem.coupon.amount > this.editedItem.deliveryCost
                  ? this.editedItem.deliveryCost
                  : this.editedItem.coupon.amount;
            } else {
              discount =
                this.editedItem.deliveryCost *
                (this.editedItem.coupon.amount / 100);

              discount =
                this.editedItem.coupon.maximumDiscount &&
                  discount > this.editedItem.coupon.maximumDiscount
                  ? this.editedItem.coupon.maximumDiscount
                  : discount;
            }
            break;
          default:
            return 0;
            break;
        }

        return discount;
      } else {
        return 0;
      }
    },
    businessInvoiceNumber: {
      get: function () {
        if (this.editedItem.businessOrderNumber) {
          return this.editedItem.businessOrderNumber;
        } else {
          return "";
        }
      },
      set: function (newValue) {
        this.editedItem.businessOrderNumber = newValue;
      },
    },
  },
  filters: {
    statusFilter: function (status) {
      switch (status) {
        case "placed":
          return "Colocada por el Cliente";
        case "accepted":
          return "Aceptada por el Comercio";
        case "enRoute":
          return "Chavo en Camino al Comercio";
        case "onDelivery":
          return "Chavo en Camino al Cliente";
        case "delivered":
          return "Entregada al Cliente";
        case "completed":
          return "Orden Completada";
        case "canceled":
          return "Cancelado por Cliente";
        case "pendingValidation":
          return "Requiere Validación";
        case "rejected":
          return "Cancelado por administración";
        default:
          return "Desconocido";
      }
    },

    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },

    filterCouponType(e) {
      let types = {
        subtotal: "subtotal",
        shipping: "envío",
        total: "total",
      };

      return types[e];
    },
  },
  methods: {
    getColor(status) {
      const result = this.searchableTags.find(({ name }) => name === status);
      return result ? result.color : "gray";
    },
    save() {
      if (this.modificationReason) {
        let collection =
          this.item.orderType == "errand" || this.item.orderType == "cargo"
            ? db.collection("ordersErrands")
            : db.collection("orders");

        const arrayUnion = fb.firestore.FieldValue.arrayUnion;

        let originalOrder = this.filteredLogs.find(
          (item) => item[".key"] == this.editedItem[".key"]
        );

        this.editedItem.orderDiscount = this.calculateCouponDiscount;

        let payload = {
          productsSubTotal: this.editedItem.productsSubTotal,
          deliveryCost: this.editedItem.deliveryCost,
          orderSubTotal: this.calculatedOrderSubTotal,
          extraFeesCharge: this.calculatedExtraFeesCharge,
          orderTip: this.editedItem.orderTip,
          orderTotal: this.calculatedOrderTotal,
          orderDiscount: this.editedItem.orderDiscount,
          couponDiscount: this.editedItem.orderDiscount,
        };

        if (this.editedItem.coupon) {
          payload.orderTotal =
            this.calculatedOrderTotal - this.calculateCouponDiscount;

          switch (this.editedItem.coupon.type) {
            case "subtotal":
              payload.originalProductsSubTotal =
                this.editedItem.productsSubTotal;

              payload.orderSubTotal =
                this.calculatedOrderSubTotal - this.calculateCouponDiscount;

              payload.originalOrderSubTotal = this.calculatedOrderSubTotal;

              payload.productsSubTotal =
                this.editedItem.productsSubTotal - this.calculateCouponDiscount;
              break;

            case "total":
              payload.originalOrderTotal = this.calculatedOrderTotal;
              payload.orderTotal =
                this.calculatedOrderTotal - this.calculateCouponDiscount;
              break;

            case "shipping":
              payload.deliveryOriginalCost = this.editedItem.deliveryCost;

              payload.deliveryCost =
                this.editedItem.deliveryCost - this.calculateCouponDiscount;
              break;

            default:
              break;
          }
        }

        let reasonData = {
          reason: this.modificationReason,
          createdBy: this.user[".key"],
          userName: this.user.name,
          createdAt: new Date(),

          //OLD FIELDS LOGS
          oldProductsSubTotal:
            originalOrder.originalProductsSubTotal ||
            originalOrder.productsSubTotal,
          oldDeliveryCost: originalOrder.deliveryCost
            ? originalOrder.deliveryCost
            : 0,
          oldOrderTip: originalOrder.orderTip ? originalOrder.orderTip : 0,
          oldOrderDiscount: originalOrder.orderDiscount
            ? originalOrder.orderDiscount
            : 0,

          //NEW FIELDS LOGS
          newProductsSubTotal:
            payload.originalProductsSubTotal || payload.productsSubTotal,
          newDeliveryCost: payload.deliveryOriginalCost || payload.deliveryCost,
          newOrderTip: payload.orderTip,
          newOrderDiscount: payload.orderDiscount,
        };

        payload.modificationReasons = arrayUnion(reasonData);

        this.formLoading = true;

        collection
          .doc(this.editedItem[".key"])
          .update(payload)
          .then(() => {
            this.loading = false;
            this.modificationReason = "";
            this.$emit("success");
          })
          .catch((err) => {
            console.log(err);
            this.snackbar = true;
            this.snackbarText = `Error al actualizar Orden ${this.editedItem.shortId} 😢`;
            this.loading = false;
          });
        // let updatedFields = {};
        // let numberCheck = [];

        // if (this.editedItem.extraFees) {
        //   updatedFields = (({
        //     businessOrderNumber,
        //     extraFees,
        //     productsSubTotal,
        //     extraFeesCharge,
        //     deliveryCost,
        //     orderSubTotal,
        //     orderTotal,
        //     orderTip,
        //     orderDiscount,
        //   }) => ({
        //     businessOrderNumber,
        //     extraFees,
        //     productsSubTotal,
        //     extraFeesCharge,
        //     deliveryCost,
        //     orderSubTotal,
        //     orderTotal,
        //     orderTip,
        //     orderDiscount,
        //   }))(this.editedItem);
        //   updatedFields.extraFees[0].extraFeeSum = updatedFields.extraFees[0]
        //     .extraFeeSum
        //     ? Number(updatedFields.extraFees[0].extraFeeSum)
        //     : 0;
        //   numberCheck.push(updatedFields.extraFees[0].extraFeeSum);
        // } else {
        //   updatedFields = (({
        //     productsSubTotal,
        //     extraFeesCharge,
        //     deliveryCost,
        //     orderSubTotal,
        //     orderTotal,
        //     orderTip,
        //     orderDiscount,
        //   }) => ({
        //     productsSubTotal,
        //     extraFeesCharge,
        //     deliveryCost,
        //     orderSubTotal,
        //     orderTotal,
        //     orderTip,
        //     orderDiscount,
        //   }))(this.editedItem);
        // }

        // updatedFields.businessOrderNumber = updatedFields.businessOrderNumber
        //   ? updatedFields.businessOrderNumber
        //   : "";
        // updatedFields.productsSubTotal = updatedFields.productsSubTotal
        //   ? Number(updatedFields.productsSubTotal)
        //   : 0;
        // updatedFields.extraFeesCharge = Number(this.calculatedExtraFeesCharge);
        // updatedFields.deliveryCost = updatedFields.deliveryCost
        //   ? Number(updatedFields.deliveryCost)
        //   : 0;

        // updatedFields.orderSubTotal = Number(this.calculatedOrderSubTotal);
        // updatedFields.orderTotal = Number(this.calculatedOrderTotal);
        // numberCheck.push(updatedFields.productsSubTotal);
        // numberCheck.push(updatedFields.extraFeesCharge);
        // numberCheck.push(updatedFields.deliveryCost);
        // numberCheck.push(updatedFields.orderSubTotal);
        // numberCheck.push(updatedFields.orderTotal);
        // updatedFields.modificationReasons = arrayUnion(reasonData);

        // if (numberCheck.includes(NaN)) {
        //   this.snackbar = true;
        //   this.snackbarText = `Todos los campos deben ser numéricos`;
        //   this.formLoading = false;
        // } else {
        //   this.loading = true;
        //   try {
        //     collection
        //       .doc(this.editedItem[".key"])
        //       .update(updatedFields)
        //       .then(() => {
        //         this.loading = false;
        //         this.modificationReason = "";
        //         this.$emit("success");
        //       })
        //       .catch((err) => {
        //         console.log(err);
        //         this.snackbar = true;
        //         this.snackbarText = `Error al actualizar Orden ${this.editedItem.shortId} 😢`;
        //         this.loading = false;
        //       });
        //   } catch (error) {
        //     console.log(error);
        //     this.snackbar = true;
        //     this.snackbarText = `Error al actualizar Orden ${this.editedItem.shortId} 😢`;
        //     this.loading = false;
        //   }
        // }
      } else {
        this.snackbarText = "Ingrese el motivo de modificación de la orden.";
        this.snackbar = true;
      }
    },
  },
  mounted() {
    this.editedItem = JSON.parse(JSON.stringify(this.item));

    this.editedItem.productsSubTotal =
      this.editedItem.originalProductsSubTotal && this.editedItem.coupon
        ? this.editedItem.originalProductsSubTotal
        : this.editedItem.productsSubTotal;

    this.editedItem.deliveryCost =
      this.editedItem.deliveryOriginalCost && this.editedItem.coupon
        ? this.editedItem.deliveryOriginalCost
        : this.editedItem.deliveryCost;
  },
};
</script>