<template>
  <div>
    <v-container fluid class="fill-height">
      <v-row class="pa-0 ma-0">
        <v-col cols="8">
          <h1>
            Órdenes
            <span class="font-weight-light">Activas</span>
          </h1>
        </v-col>

        <v-col cols="4" class="text-right d-flex flex-end">
          <v-tooltip
            v-if="['sudo', 'admin', 'ACC', 'AC', 'driver'].includes($store.state.user.type)"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                fab
                v-on="on"
                dark
                :elevation="0"
                small
                :loading="loading"
                @click="chartDialog = true"
              >
                <v-icon x-large dark>fas fa-chart-area</v-icon>
              </v-btn>
            </template>
            <span>Estadísticas</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="rgba(0, 0, 0, 0.6)"
                fab
                v-on="on"
                class="ml-2"
                dark
                small
                :elevation="0"
                :loading="loading"
                @click="reportsDialog = true"
              >
                <v-icon x-large dark>fa-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Exportar .xlsx</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" md="4">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="date"
            persistent
            scrollable
            max-width="300px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                label="Filtrar por fecha"
                prepend-icon="fa-calendar-alt"
                readonly
                filled
                rounded
                hide-details
                :disabled="loading"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date"
              range
              :title-date-format="title"
              scrollable
              :max="today"
            >
              <v-row no-gutters style="flex-wrap: nowrap">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  rounded
                  outlined
                  color="primary"
                  @click="modal = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0 ml-2"
                  rounded
                  color="primary"
                  @click="
                    $refs.dialog.save(date);
                    updateDateFilter();
                  "
                >
                  Filtrar
                </v-btn>
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="getCities"
            :items="cities"
            prepend-icon="fa-city"
            label="Filtrar por ciudad"
            multiple
            item-text="name"
            item-value=".key"
            aria-autocomplete="false"
            hide-details
            filled
            rounded
            :allow-overflow="false"
            clearable
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="tagsToSearch"
            :items="searchableTags"
            prepend-icon="fa-filter"
            label="Filtrar por estado"
            item-text="name"
            item-value="name"
            multiple
            aria-autocomplete="false"
            autocomplete="off"
            hide-details
            filled
            rounded
            :allow-overflow="false"
            clearable
          >
            <template v-slot:selection="data">
              <v-list-item-avatar
                :color="data.item.color"
                size="8"
                class="ma-1"
              >
                <span class="white--text headline"></span>
              </v-list-item-avatar>
            </template>
            <template v-slot:item="data">
              <template v-if="checkNotObject(data)">
                <v-list-item-content> {{ data.item }}</v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar
                  :color="data.item.color"
                  size="8"
                  class="mr-2"
                >
                  <span class="white--text headline"></span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.name | statusFilter }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col cols="12" :class="{ 'table-mobile-r': isMobile }">
          <v-data-table
            :headers="isMobile ? headerMobile : getHeaders"
            :items="filteredLogs"
            :items-per-page="10"
            :loading="loading"
            sort-by="progressPlacedAt"
            :sort-desc="true"
            :single-expand="true"
            :expanded.sync="expanded"
            show-expand
            :search="search"
            item-key=".key"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
<!--    itemsPerPageOptions es la lista de cuanto quiere uno que aparezca    -->
            <template v-slot:top>
              <!-- EDIT ORDER DIALOG -->
              <v-dialog
                v-if="dialog"
                persistent
                v-model="dialog"
                max-width="500px"
                scrollable
              >
                <edit-order
                  :searchableTags="searchableTags"
                  @cancel="dialog = false"
                  :filteredLogs="filteredLogs"
                  @success="orderEdited"
                  :item="editedItem"
                />
              </v-dialog>

              <!-- ACCEPT ORDER DIALOG -->
              <v-dialog
                v-if="dialogAccept"
                v-model="dialogAccept"
                max-width="500px"
                persistent
                scrollable
              >
                <accept-order
                  @cancel="dialogAccept = false"
                  :process="process"
                  @success="orderAccepted"
                  :item="editedItem"
                />
              </v-dialog>
            </template>

            <template v-slot:[`item.progressCurrentStatus`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="getColor(item.progressCurrentStatus)"
                    dark
                    v-on="on"
                    @click="showChangeStatus(item)"
                    style="
                      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                      cursor: pointer;
                    "
                    >{{ item.progressCurrentStatus | statusFilter }}
                  </v-chip>
                </template>
                <span>Clic para cambiar el estado de la orden</span>
              </v-tooltip>
            </template>
<!--            TODO: Este es el valor costo Envio de la lista-->
            <template v-slot:[`item.deliveryCost`]="{ item }">
              {{ item.deliveryCost | currency }}
            </template>
            <template v-slot:[`item.badWeatherFee`]="{ item }">
              {{ item.isRaining ? item.badWeatherFee : '0' | currency }}
            </template>

            <template v-slot:[`item.prizes`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <i
                    v-on="on"
                    class="fas fa-gift"
                    style="font-size: 20px; color: #FF1744; cursor: pointer"
                    v-if="item.prizes && item.prizes.length"
                  />
                </template>
                <div>
                  <span>Premios en esta order</span>
                  <p
                    style="color: white"
                    class="font-weight:bold"
                    v-for="(prize, id) of item.prizes"
                    :key="id"
                  >
                    - {{ prize.description }}
                  </p>
                </div>
              </v-tooltip>
            </template>

            <template v-slot:[`item.orderSubTotal`]="{ item }">
              {{ item.orderSubTotal | currency }}
            </template>

            <template v-slot:[`item.productsSubTotal`]="{ item }">
              {{ item.productsSubTotal | currency }}
            </template>

            <template v-slot:[`item.progressTotalFiltered`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <p style="cursor: pointer" v-on="on">
                    {{ item.progressTotalFiltered }}
                  </p>
                </template>
                <span>Tiempo orden Colocada - Entregada</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.progressCompletedAtFiltered`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <p style="cursor: pointer" v-on="on">
                    {{ item.progressCompletedAtFiltered }}
                  </p>
                </template>
                <span>Tiempo orden Entregada - Completada</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.orderChannel`]="{ item }">
              <p class="pa-0 ma-0" v-if="item.orderChannel == 'menuhn'">
                Menu HN
              </p>
              <p
                class="pa-0 ma-0"
                v-if="
                  item.orderChannel == 'ocho_cargo_api' ||
                  item.orderChannel == 'ocho_cargo'
                "
              >
                Ocho cargo
              </p>

              <p
                class="pa-0 ma-0"
                v-if="
                  item.orderChannel == 'App_android' ||
                  item.orderChannel == 'App_iOS'
                "
              >
                Ocho App
              </p>

              <!-- <v-img
                v-if="item.orderChannel == 'menuhn'"
                width="50"
                :src="menu"
              ></v-img>
              <v-img
                v-if="
                  item.orderChannel == 'ocho_cargo_api' ||
                  item.orderChannel == 'ocho_cargo'
                "
                width="50"
                :src="cargo"
              ></v-img> -->
            </template>

            <template v-slot:[`item.orderPaymentMethod`]="{ item }">
              <v-chip
                :color="colorPaymentMethod(item.orderPaymentMethod)"
                :dark="item.orderPaymentMethod !== 'card'"
                style="
                  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                  cursor: pointer;
                "
              >
                {{
                  (item.orderPaymentMethod2
                    ? item.orderPaymentMethod2
                    : item.orderPaymentMethod) | orderPaymentMethodFilter
                }}
              </v-chip>
            </template>

            <template v-slot:[`item.timeFromPlacedStatus`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-show="
                      item.progressCurrentStatus === 'placed' &&
                      item.timeFromPlacedStatus >= 3
                    "
                    class="mx-1"
                    fab
                    v-on="on"
                    :dark="$vuetify.theme.dark"
                    x-small
                    :elevation="0"
                  >
                    <v-icon color="red" v-on="on" x-large>
                      fas fa-exclamation-circle
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ item.timeFromPlacedStatus }} minutos desde que el cliente
                  colocó esta orden
                </span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.isRaining`]="{ item }">
              <v-tooltip v-if="item.isRaining" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-1"
                    fab
                    v-on="on"
                    :dark="$vuetify.theme.dark"
                    x-small
                    :elevation="0"
                  >
                    <v-icon color="#858585" v-on="on" x-large>
                      fas fa-cloud-rain
                    </v-icon>
                  </v-btn>
                </template>
                <span> Orden colocada con cargo extra por lluvia. </span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.shortId`]="{ item }">
              <v-tooltip v-if="item.shortId" right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.shortId)"
                  >
                    {{ item.shortId }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.userName`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block; text-transform: capitalize"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.userName)"
                  >
                    {{ item.userName }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.progressPlacedAtFiltered`]="{ item }">
              <span class>{{ item.progressPlacedAtRelative }}</span>
              <br />
              <span
                :class="
                  $vuetify.theme.dark
                    ? 'grey--text'
                    : 'grey--text text--darken-1'
                "
              >
                {{ item.progressPlacedAtFiltered }}
              </span>
            </template>

            <template v-slot:[`item.orderType`]="{ item }">
              <v-chip
                v-if="item.orderType == 'pickUp'"
                color="yellow"
                style="
                  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                  cursor: pointer;
                "
              >
                {{ item.orderType | orderTypeName }}
              </v-chip>
              <p v-else class="ml-2">
                {{ item.orderType | orderTypeName }}
              </p>
            </template>

            <template v-slot:[`item.businessFleetType`]="{ item }">
              <span v-if="item.deliveryType == 'errand'"> Ocho </span>
              <span v-else class>{{
                item.businessFleetType | fleetTypeName
              }}</span>
            </template>
            <template v-slot:[`item.orderTotal`]="{ item }">
              <span class>{{ item.orderTotal | currency}}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="extra(item)"
                    class="mx-1"
                    fab
                    v-on="on"
                    :disabled="item.orderPaymentMethod != 'card'"
                    :dark="$vuetify.theme.dark"
                    x-small
                    :elevation="0"
                    v-if="['sudo', 'admin', 'AF'].includes(user.type)"
                    color="rgba(0, 0, 0, 0.6)"
                  >
                    <v-icon class="white--text" dark>fas fa-coins</v-icon>
                  </v-btn>
                </template>
                <span>Realizar cobro extra / Reembolso</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="editItem(item)"
                    class="mx-1"
                    fab
                    v-on="on"
                    dark
                    x-small
                    :elevation="0"
                    color="secondary"
                  >
                    <v-icon dark>fas fa-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Detalles de la Orden</span>
              </v-tooltip>

              <v-tooltip
                v-if="['sudo', 'admin', 'AF', 'AC'].includes(user.type)"
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="acceptItem(item)"
                    class="mx-1"
                    fab
                    v-on="on"
                    :dark="$vuetify.theme.dark"
                    :disabled="!['placed'].includes(item.progressCurrentStatus)"
                    x-small
                    :elevation="0"
                    v-if="['sudo', 'admin', 'AF'].includes(user.type)"
                    color="primary"
                  >
                    <v-icon dark>fa-check</v-icon>
                  </v-btn>
                </template>
                <span>Aceptar Orden</span>
              </v-tooltip>

              <v-tooltip
                v-if="['sudo', 'admin', 'AF'].includes(user.type)"
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-1"
                    fab
                    v-if="['sudo', 'admin', 'AF'].includes(user.type)"
                    v-on="on"
                    @click="cancelOrder(item)"
                    :dark="$vuetify.theme.dark"
                    :disabled="
                      disabledCancelOrder.includes(item.progressCurrentStatus)
                    "
                    x-small
                    :elevation="0"
                    color="error"
                  >
                    <v-icon dark>fas fa-ban</v-icon>
                  </v-btn>
                </template>
                <span>Rechazar orden</span>
              </v-tooltip>
              <v-tooltip
                v-if="['sudo', 'admin', 'AF'].includes(user.type)"
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-1"
                    fab
                    v-on="on"
                    :dark="$vuetify.theme.dark"
                    :disabled="item.progressCurrentStatus == 'completed'"
                    x-small
                    @click="completeTransaction(item)"
                    :elevation="0"
                    color="secondary"
                  >
                    <v-icon dark>fas fa-credit-card</v-icon>
                  </v-btn>
                </template>
                <span>Completar transacción</span>
              </v-tooltip>

              <v-tooltip
                v-if="['sudo', 'admin', 'AF'].includes(user.type)"
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-1"
                    fab
                    v-on="on"
                    :dark="$vuetify.theme.dark"
                    x-small
                    :disabled="item.hasCoupon || item.orderType != 'regular'"
                    @click="addCoupon(item)"
                    :elevation="0"
                    color="green"
                  >
                    <v-icon color="white" dark>fas fa-ticket-alt</v-icon>
                  </v-btn>
                </template>
                <span>Crear cupón</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-4">
                <errand-details
                  v-if="item.orderType == 'errand' || item.orderType == 'cargo'"
                  :item="item"
                />
                <order-details v-else :item="item" />
              </td>
            </template>

            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>

            <template slot="body.append">
              <tr class="gray--text">
                <th colspan="100%">
                  <v-row class="mt-5">
                    <v-col cols="1" sm="1" md="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            large
                            v-on="on"
                            style="cursor: pointer"
                            color="primary"
                            @click="modalShowColumns = true"
                          >
                            fas fa-eye-slash
                          </v-icon>
                        </template>
                        <span>Mostrar / Ocultar columnas</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- CANCEL ORDER DIALOG -->
    <v-dialog
      persistent
      max-width="550"
      v-model="modalCancel"
      v-if="modalCancel"
      scrollable
    >
      <cancel-order
        @cancel="modalCancel = false"
        :itemToCancel="itemToCancel"
        @success="orderCanceled"
      />
    </v-dialog>

    <!-- COMPLETE TRANSACTION DIALOG -->
    <v-dialog
      max-width="600"
      persistent
      v-model="modalCaptureOrder"
      v-if="modalCaptureOrder"
    >
      <complete-transaction
        @cancel="modalCaptureOrder = false"
        @success="captureCompleted"
        :item="orderToCapture"
      />
    </v-dialog>

    <!-- SHOW AND HIDE TABLE COLUMNS  -->
    <v-dialog
      max-width="450"
      persistent
      v-if="modalShowColumns"
      v-model="modalShowColumns"
      scrollable
    >
      <show-columns
        @success="modalShowColumns = false"
        @cancel="modalShowColumns = false"
        :headers="headers"
      />
    </v-dialog>

    <!-- CHANGE ORDER STATUS DIALOG -->
    <v-dialog
      max-width="450"
      v-if="dialogOrderStatus"
      persistent
      v-model="dialogOrderStatus"
    >
      <change-status
        :item="editedItem"
        @success="statusChanged"
        :searchableTags="searchableTags"
        @cancel="dialogOrderStatus = false"
      />
    </v-dialog>

    <!-- CHANGE ORDER STATUS DIALOG -->
    <v-dialog
      max-width="700px"
      v-if="reportsDialog"
      persistent
      v-model="reportsDialog"
    >
      <reports
        :cityId="selectedCities"
        :tags="tagsToSearch"
        :date="date"
        :cities="cities"
        :searchableTags="searchableTags"
        @cancel="reportsDialog = false"
      />
    </v-dialog>

    <v-dialog
      max-width="70%"
      v-if="chartDialog"
      persistent
      v-model="chartDialog"
      scrollable
    >
      <charts
        :totalOrderTime="totalOrderTime"
        :data="filteredLogs"
        :errands="errands"
        :orders="orders"
        :date="date"
        :tagsToSearch="tagsToSearch"
        :cities="cities"
        :searchableTags="searchableTags"
        @cancel="chartDialog = false"
      ></charts>
    </v-dialog>

    <v-dialog
      max-width="900"
      v-if="extraDialog"
      persistent
      v-model="extraDialog"
    >
      <extra-charge
        :item="selectedItem"
        @cancel="extraDialog = false"
      ></extra-charge>
    </v-dialog>

    <v-dialog
      max-width="900"
      v-if="couponDialog"
      persistent
      scrollable
      v-model="couponDialog"
    >
      <coupon
        type="new"
        @success="handleCouponSuccess"
        :order="selectedItem"
        :city="selectedItem.cityId"
        country="8HxIs06o3vDI2woWpqw3"
        @cancel="couponDialog = false"
      ></coupon>
    </v-dialog>

    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
  </div>
</template>
<script>
import { db } from "@/firebase";
import Lottie from "../components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
// import { Parser } from "json2csv";
import JsonExcel from "vue-json-excel";

import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import "moment-duration-format";

import orderDetails from "./c19/order-details";
import errandDetails from "./c19/errand-details";
import cancelOrder from "./c19/cancel-order";
import changeStatus from "./c19/change-status";
import completeTransaction from "./c19/complete-transaction";
import showColumns from "./c19/show-hide-columns";
import editOrder from "./c19/edit-order";
import acceptOrder from "./c19/accept-order";
import reports from "./c19/reports";
import charts from "./c19/charts";
import extraCharge from "./c19/extraCharge";
import coupon from "../views/sudo/coupons4/AddEditCoupon";

export default {
  name: "c19",
  components: {
    lottie: Lottie,
    orderDetails,
    errandDetails,
    cancelOrder,
    changeStatus,
    completeTransaction,
    showColumns,
    editOrder,
    acceptOrder,
    downloadExcel: JsonExcel,
    reports,
    charts,
    extraCharge,
    coupon,
  },
  mixins: [lottieSettings],
  data() {
    return {
      modal: null,
      isMobile: screen.width <= 800 ? true : false,
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],
      agent: null,
      extraDialog: false,
      selectedItem: null,
      cargo: require("@/assets/apps/cargo.svg"),
      pay: require("@/assets/apps/pay.svg"),
      menu: require("@/assets/apps/menu.svg"),
      agents: [],
      dialog: false,
      dialogAccept: false,
      snackbar: false,
      snackbarText: null,
      loading: true,
      formLoading: false,
      chartDialog: false,
      orders: [],
      cities: [],
      currentTime: Date.now(),
      // selectedCities: [],
      expanded: [],
      itemsPerPageOptions: [4, 8, 12],
      itemsPerPage: 4,
      tagsToSearch: [],
      defaultItem: {},
      editedItem: {},
      process: "",
      itemToCancel: {},
      orderToCapture: {},
      totalOrderTime: 0,
      couponDialog: false,
      modalCancel: false,
      modalCaptureOrder: false,
      modalShowColumns: false,
      dialogOrderStatus: false,
      dialogModificationHistoy: false,
      reportsDialog: false,
      errands: [],
      disabledCancelOrder: ["delivered", "rejected", "canceled", "completed"],
      searchableTags: [
        { name: "placed", color: "lime darken-1" },
        { name: "accepted", color: "green" },
        { name: "enRoute", color: "amber" },
        { name: "onDelivery", color: "orange" },
        { name: "delivered", color: "cyan" },
        { name: "completed", color: "blue" },
        { name: "canceled", color: "grey" },
        { name: "pendingValidation", color: "purple" },
        { name: "rejected", color: "red" },
      ],
      headers: [
        {
          value: "timeFromPlacedStatus",
          active: true,
          label: "Tiempo de retraso",
          class: "nowrap",
        },

        {
          value: "isRaining",
          active: true,
          label: "Climaduro",
          class: "nowrap",
        },

        {
          value: "prizes",
          active: true,
          label: "premios",
          class: "nowrap",
        },

        {
          text: "Estado",
          value: "progressCurrentStatus",
          align: "center",
          active: true,
          class: "nowrap",
        },
        {
          text: "Ciudad",
          value: "cityName",
          active: true,
          width: "150px",
        },
        {
          text: "Estado del Pago",
          value: "orderPaymentStatus",
          align: "center",
          active: true,
          class: "nowrap",
        },
        {
          text: "Forma del Pago",
          value: "orderPaymentMethod",
          align: "center",
          active: true,
          class: "nowrap",
        },
        {
          text: "Order ID",
          value: "shortId",
          align: "center",
          active: true,
          class: "nowrap",
        },
        {
          text: "Cliente",
          value: "userName",
          active: true,
          class: "nowrap",
        },
        {
          text: "Cantidad órdenes",
          value: "orderCounter",
          active: true,
          class: "nowrap",
        },

        {
          text: "Tarjetas agregadas",
          value: "cardsAdded",
          active: true,
        },
        {
          text: "Direcciones agregadas",
          value: "addressesAdded",
          active: true,
        },
        {
          text: "Negocio",
          value: "businessName",
          width: "200px",
          active: true,
          class: "nowrap",
        },

        {
          text: "Tipo de delivery",
          value: "orderType",
          active: true,
          class: "nowrap",
          // width : '500px'
        },
        {
          text: "Flota",
          value: "businessFleetType",
          active: true,
          class: "nowrap",
        },
        {
          value: "orderChannel",
          active: true,
          text: "Origen",
          class: "nowrap",
          width: "150px",
        },
        {
          text: "Costo Envío",
          value: "deliveryCost",
          active: true,
          class: "nowrap",
        },
        {
          text: "Cargo por lluvia",
          value: "badWeatherFee",
          active: true,
          filterable: true,
          class: "nowrap",
        },
        {
          text: "Producto",
          value: "productsSubTotal",
          active: true,
          class: "nowrap",
        },
        {
          text: "Monto Total",
          value: "orderTotal",
          align: "end",
          active: true,
          class: "nowrap",
        },
        {
          text: "Aceptada",
          value: "progressAcceptedAtFiltered",
          filterable: false,
          active: true,
          class: "nowrap",
        },
        {
          text: "Asignada",
          value: "progressPickupStartedAtFiltered",
          filterable: false,
          active: true,
          class: "nowrap",
        },
        {
          text: "En camino",
          value: "progressDeliveryStartedAtFiltered",
          filterable: false,
          active: true,
          class: "nowrap",
        },
        {
          text: "Entregada",
          value: "progressDeliveryAtFiltered",
          filterable: false,
          active: true,
          class: "nowrap",
        },
        {
          text: "Completada",
          value: "progressCompletedAtFiltered",
          filterable: false,
          active: true,
          class: "nowrap",
        },
        {
          text: "Tiempo Total",
          value: "progressTotalFiltered",
          filterable: false,
          active: true,
          class: "nowrap",
        },
        {
          text: "Creada",
          value: "progressPlacedAtFiltered",
          filterable: false,
          width: "150",
          active: true,
          class: "nowrap",
        },
        {
          value: "actions",
          width: "380",
          active: true,
          sortable: false,
          label: "Acciones",
          align: "end",
          roles: ["sudo", "admin", "AF", "ACC", "AC"],
        },
      ],
    };
  },
  filters: {
    toHours(seconds) {
      var duration = moment.duration(seconds, "seconds");
      var formatted = duration.format("hh:mm:ss");
      return formatted;
    },
    formatNumber: function (value) {
      if (!value) return 0;
      return new Intl.NumberFormat().format(value);
    },
    orderTypeName: function (value) {
      let deliveryType = {
        regular: "Regular",
        pickUp: "Recogida",
        regularPickUp: "Recogida regular",
        none: "N/A",
        errand: "Mandadito",
        airTime: "N/A",
        "": "N/A",
        cargo: "Mandadito",
        dineIn: "Comer en restaurante",
        room: "Habitación",
      };

      return deliveryType[value];
    },
    fleetTypeName: function (value) {
      let fleetType = {
        ocho: "Ocho",
        mixed: "Mixto",
        own: "Propia",
        "": "N/A",
      };

      return fleetType[value];
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },

    statusFilter: function (status) {
      switch (status) {
        case "placed":
          return "Colocada por el Cliente";
        case "accepted":
          return "Aceptada por el Comercio";
        case "enRoute":
          return "Chavo en Camino al Comercio";
        case "onDelivery":
          return "Chavo en Camino al Cliente";
        case "delivered":
          return "Entregada al Cliente";
        case "completed":
          return "Orden Completada";
        case "canceled":
          return "Cancelado por Cliente";
        case "pendingValidation":
          return "Requiere Validación";
        case "rejected":
          return "Cancelado por administración";
        default:
          return "Desconocido";
      }
    },
    tsToDate: function (timestamp) {
      if (!timestamp) return "n/a";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY, HH:mm:ss [GMT]Z");
    },
    tsToTime: function (timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("HH:mm:ss:SSS");
    },
    orderPaymentMethodFilter(method) {
      switch (method) {
        case "card":
          return "Tarjeta";
        case "cash":
          return "Efectivo";
        case "credit":
          return "Al crédito";
        case "tengo":
          return "Tengo";
        case "tigoMoney":
          return "Tigo Money";
        case "dilo":
          return "Dilo";
        case "coupon":
          return "Cupón";
        case "opl":
          return "Ocho Pay";
        default:
          return "Desconocido";
      }
    },
  },
  watch: {
    async selectedCities(val) {
      this.getData();
    },

    async date(val) {
      if (!this.selectedCities || !this.selectedCities.length) {
        return;
      }

      if (val.length > 1) {
        let ordersCollection = db.collection(`orders`);
        let errandsCollection = db.collection(`ordersErrands`);

        this.loading = true;
        this.$store.commit("setSearch", "");

        this.orders = [];
        this.errands = [];

        let ordersQuery = ordersCollection
          .where("cityId", "in", this.selectedCities)
          .where(
            "progressPlacedAt",
            ">",
            moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
          )
          .where(
            "progressPlacedAt",
            "<",
            moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
          );

        let errandsQuery = errandsCollection
          .where("cityId", "in", this.selectedCities)
          .where(
            "progressPlacedAt",
            ">",
            moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
          )
          .where(
            "progressPlacedAt",
            "<",
            moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
          );

        if (this.$store.state.user.type === "businessOwner") {
          ordersQuery = ordersQuery.where(
            "businessId",
            "in",
            this.$store.state.user.businesses
          );
        }

        await this.$binding("orders", ordersQuery);
        await this.$binding("errands", errandsQuery);
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapState(["ordersHeaders", "user", "selectedCities"]),

    airTimeOrders() {
      let total = this.orders.filter((e) => e.orderType == "airTime");
      return total.length || 0;
    },

    getCities: {
      get() {
        return this.selectedCities;
      },
      set(e) {
        this.updateCity(e);
      },
    },
    excelFields() {
      return {
        Estado: "progressCurrentStatus",
        "Tipo de orden": "orderType",
        "ID del negocio": "businessId",
        "Nombre del Negocio": "businessName",
        "ID de Orden": "shortId",
        "# de Factura": "businessOrderNumber",
        Cliente: "userName",
        "# de Pedidos": "orderCounter",
        "Teléfono Cliente": "userPhone",
        "Correo Cliente": "userEmail",
        "Zona de Dirección Cliente": "userAddressZone",
        "Dirección Cliente": "userAddressNeighborhood",
        "Coordenadas del Cliente": "coordinates",
        "Conductor Asignado": "deliveryInfo.driverName",
        "Teléfono del conductor": "deliveryInfo.phone",
        "Distancia Recorrido (km)": "deliveryDistance",
        "Propina Conductor": "orderTip",
        "Costo de envío": "deliveryCost",
        "Cargos extras": "extraFeesCharge",
        "Descuento por Cupón": "couponDiscount",
        "Total de productos": "productsSubTotal",
        "Cargo por lluvia": "badWeatherFee",
        "Total de la Orden": "orderTotal",
        "Método de pago": "orderPaymentMethod",
        "Fecha de Creación": "created",
      };
    },
    excelName() {
      return `Reporte-Ocho-Panel-${this.today}.xls`;
    },
    search() {
      return this.$store.state.search;
    },

    today() {
      return new Date().toISOString().substr(0, 10);
    },

    filteredLogs() {
      this.totalOrderTime = 0;

      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });

      // listado de ordenes y mandaditos, aqui agregaria lo otros tipos de ordenes
      const allItems = [...this.orders, ...this.errands];

      // console.log("lista de order: x",JSON.stringify(allItems));
      let orders = allItems.map((x) => {
        //accumulator for total time for all orders
        if (x.progressPlacedAt && x.progressDeliveredAt) {
          let a = moment
            .unix(x.progressPlacedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          let b = moment
            .unix(x.progressDeliveredAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");

          let seconds = moment
            .utc(
              moment(b, "DD/MM/YYYY HH:mm:ss").diff(
                moment(a, "DD/MM/YYYY HH:mm:ss")
              )
            )
            .format("X");
          this.totalOrderTime = this.totalOrderTime + Number(seconds);
        }

        x.progressPlacedAtFiltered = x.progressPlacedAt
          ? moment(x.progressPlacedAt.toDate())
              .tz("America/Tegucigalpa")
              .format("DD/MM, hh:mm A")
          : "";

        x.progressPlacedAtRelative = x.progressPlacedAt
          ? moment(x.progressPlacedAt.toDate()).fromNow()
          : "";

        if (x.progressAcceptedAt) {
          let a = moment
            .unix(x.progressPlacedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          let b = moment
            .unix(x.progressAcceptedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          x.progressAcceptedAtFiltered = moment
            .utc(
              moment(b, "DD/MM/YYYY HH:mm:ss").diff(
                moment(a, "DD/MM/YYYY HH:mm:ss")
              )
            )
            .format("mm:ss");
        }
        if (x.progressAcceptedAt && x.progressPickupStartedAt) {
          let a = moment
            .unix(x.progressAcceptedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          let b = moment
            .unix(x.progressPickupStartedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");

          x.progressPickupStartedAtFiltered = moment
            .utc(
              moment(b, "DD/MM/YYYY HH:mm:ss").diff(
                moment(a, "DD/MM/YYYY HH:mm:ss")
              )
            )
            .format("mm:ss");
        }
        if (x.progressDeliveryStartedAt && x.progressPickupStartedAt) {
          let a = moment
            .unix(x.progressPickupStartedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          let b = moment
            .unix(x.progressDeliveryStartedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          x.progressDeliveryStartedAtFiltered = moment
            .utc(
              moment(b, "DD/MM/YYYY HH:mm:ss").diff(
                moment(a, "DD/MM/YYYY HH:mm:ss")
              )
            )
            .format("mm:ss");
        }

        if (x.progressDeliveryStartedAt && x.progressDeliveredAt) {
          let a = moment
            .unix(x.progressDeliveryStartedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          let b = moment
            .unix(x.progressDeliveredAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          x.progressDeliveryAtFiltered = moment
            .utc(
              moment(b, "DD/MM/YYYY HH:mm:ss").diff(
                moment(a, "DD/MM/YYYY HH:mm:ss")
              )
            )
            .format("mm:ss");
        }
        if (x.progressDeliveredAt && x.progressCompletedAt) {
          let a = moment
            .unix(x.progressDeliveredAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          let b = moment
            .unix(x.progressCompletedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          x.progressCompletedAtFiltered = moment
            .utc(
              moment(b, "DD/MM/YYYY HH:mm:ss").diff(
                moment(a, "DD/MM/YYYY HH:mm:ss")
              )
            )
            .format("mm:ss");
        }
        if (x.progressDeliveredAt && x.progressPlacedAt) {
          let a = moment
            .unix(x.progressPlacedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");

          let b = moment
            .unix(x.progressDeliveredAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");

          let seconds = moment(b, "DD/MM/YYYY HH:mm:ss").diff(
            moment(a, "DD/MM/YYYY HH:mm:ss"),
            "seconds"
          );

          x.progressTotalFiltered = this.getMinutes(seconds);
        }

        if (x.progressPickupStartedAt && x.progressDeliveredAt) {
          let a = moment
            .unix(x.progressPickupStartedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");

          let b = moment
            .unix(x.progressDeliveredAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          x.progressPickupDelivered = moment
            .utc(
              moment(b, "DD/MM/YYYY HH:mm:ss").diff(
                moment(a, "DD/MM/YYYY HH:mm:ss")
              )
            )
            .format("mm:ss");
        }

        x.orderTotalFiltered = x.orderTotal
          ? formatter.format(x.orderTotal)
          : formatter.format(0);
        x.updatedAtRelative = x.updatedAt
          ? moment(x.updatedAt.toDate()).fromNow()
          : "";

        return x;
      });

      if (this.tagsToSearch && this.tagsToSearch.length) {
        orders = orders.filter((i) =>
          this.tagsToSearch.includes(i.progressCurrentStatus)
        );
      }

      // console.log(orders);
      return orders;
    },

    excelItems() {
      return this.filteredLogs.map((data) => {
        let item = data;
        item.coordinates = `${
          data.userAddressLatitude ? data.userAddressLatitude : 0
        } , ${data.userAddressLongitude ? data.userAddressLongitude : 0}`;

        item.couponDiscount =
          item.couponType == "freeShipping"
            ? "Envío gratis"
            : item.couponType == "percent"
            ? item.productsSubTotal * (item.couponAmount / 100)
            : item.couponType == "amount"
            ? item.couponAmount
            : 0;

        item.created = moment(item.progressPlacedAt.toDate())
          .tz("America/Tegucigalpa")
          .format("LLLL");

        return item;
      });
    },

    getHeaders() {
     const consulta = this.ordersHeaders.filter(
         (item) =>
             item.active &&
             (!item.roles || (item.roles && item.roles.includes(this.user.type)))
     );
     console.log("consulta: ", consulta);
     return consulta;
      // return this.ordersHeaders.filter(
      //   (item) =>
      //     item.active &&
      //     (!item.roles || (item.roles && item.roles.includes(this.user.type)))
      // );
    },
    headerMobile() {
      let headerM = JSON.parse(JSON.stringify(this.getHeaders));
      let dataR = [];
      for (let i = 0; i < headerM.length; i++) {
        const element = headerM[i];
        switch (element.value) {
          case "progressCurrentStatus":
            dataR.push(element);
            break;
          case "orderPaymentMethod":
            dataR.push(element);
            break;
          case "shortId":
            dataR.push(element);
            break;
          case "userName":
            dataR.push(element);
            break;
          case "businessName":
            dataR.push(element);
            break;
          case "orderTotal":
            dataR.push(element);
            break;
          case "progressPlacedAtFiltered":
            dataR.push(element);
            break;
          case "actions":
            dataR.push(element);
            break;
          default:
            element.active = false;
            break;
        }
      }

      return dataR;
    },
  },
  methods: {
    ...mapActions(["addBusiness", "updateCity"]),
    checkNotObject(data) {
      return typeof data.item !== "object";
    },

    addCoupon(item) {
      this.selectedItem = item;
      this.couponDialog = true;
    },

    extra(item) {
      this.selectedItem = item;
      this.extraDialog = true;
    },

    async getData() {
      if (!this.selectedCities || !this.selectedCities.length) {
        this.orders = [];
        this.errands = [];
        return;
      }
      let orderCollection = db.collection(`orders`);
      let errandsCollection = db.collection("ordersErrands");

      this.loading = true;
      this.$store.commit("setSearch", "");
      this.orders = [];
      this.errands = [];

      let ordersQuery = orderCollection
        .where("cityId", "in", this.selectedCities)
        .where(
          "progressPlacedAt",
          ">",
          moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
        )
        .where(
          "progressPlacedAt",
          "<",
          moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
        );

      let errandsQuery = errandsCollection
        .where("cityId", "in", this.selectedCities)
        .where(
          "progressPlacedAt",
          ">",
          moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
        )
        .where(
          "progressPlacedAt",
          "<",
          moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
        );

      if (this.$store.state.user.type === "businessOwner") {
        ordersQuery = ordersQuery.where(
          "businessId",
          "in",
          this.$store.state.user.businesses
        );
      }

      await this.$binding("orders", ordersQuery);
      await this.$binding("errands", errandsQuery);
      this.loading = false;
    },
    async handleCouponSuccess() {
      this.couponDialog = false;

      await db.collection("orders").doc(this.selectedItem[".key"]).update({
        hasCoupon: true,
      });

      this.snackbarText = `Cupón creado exitosamente.`;
      this.snackbar = true;
    },
    getMinutes(seconds) {
      if (!seconds) {
        return "00:00";
      }

      let minutes = (seconds / 60).toFixed(2).toString().split(".");
      let calSecond = Number.parseInt((minutes[1] / 100) * 60);

      return `${minutes[0].toString().padStart(2, 0)}:${calSecond
        .toString()
        .padStart(2, 0)}`;
    },

    colorPaymentMethod(method) {
      switch (method) {
        case "card":
          return "rgba(0, 0, 0, 0.1)";
        case "cash":
          return "light-green";
        case "tengo":
          return "indigo";
        case "tigoMoney":
          return "#00377b";
        case "dilo":
          return "#f14244";
        case "coupon":
          return "purple";
        case "opl":
          return "#e74c3c";
        case "credit":
          return "#5F9EA0";
        default:
          return "black";
      }
    },

    showChangeStatus(item) {
      let allowedStatus = ["accepted", "enRoute", "onDelivery"];
      if (allowedStatus.includes(item.progressCurrentStatus)) {
        this.editedItem = Object.assign({}, item);
        this.dialogOrderStatus = true;
      } else {
        this.snackbarText =
          "No es permitido cambiar el estado actual de la orden.";
        this.snackbar = true;
      }
    },
    statusChanged() {
      this.dialogOrderStatus = false;
      this.snackbarText = `Orden ${this.editedItem.shortId} modificada con éxito 👍`;
      this.snackbar = true;
    },

    cancelOrder(item) {
      this.itemToCancel = item;
      this.modalCancel = true;
    },
    orderAccepted() {
      this.snackbarText = `Orden ${this.editedItem.shortId} aceptada con éxito 👍`;
      this.snackbar = true;
      this.dialogAccept = false;
    },
    completeTransaction(item) {
      this.orderToCapture = item;
      this.modalCaptureOrder = true;
    },
    sumField(key) {
      return this.filteredLogs.reduce((a, b) => a + (b[key] || 0), 0);
    },

    // errandsTotal() {
    //   return this.errands.length;
    // },

    title() {
      return "Selecciona las fechas a filtrar";
    },

    orderCanceled() {
      this.snackbarText = "Orden rechazada exitosamente.";
      this.snackbar = true;
      this.modalCancel = false;
    },

    orderEdited() {
      this.snackbar = true;
      this.snackbarText = `Orden ${this.editedItem.shortId} actualizada con éxito 👍`;
      this.dialog = false;
    },

    captureCompleted() {
      this.snackbarText = "Transacción completada correctamente.";
      this.snackbar = true;
      this.modalCaptureOrder = false;
    },

    acceptItem(item, process) {
      this.process = process;
      this.editedItem = Object.assign({}, item);
      this.dialogAccept = true;
    },

    editItem(item) {
      if (!item.couponId) {
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
        return;
      }
      db.collection("coupons")
        .doc(item.couponId)
        .get()
        .then((res) => {
          this.editedItem = Object.assign({}, item);
          this.editedItem.couponRedeemCode = res.data().redeemCode;
          this.editedItem.couponDiscount =
            item.couponType == "percent"
              ? (item.orderOriginalSubTotal * item.couponAmount) / 100
              : item.couponType == "amount"
              ? item.couponAmount
              : item.couponType == "allFree"
              ? item.orderOriginalSubTotal
              : 0;
          this.dialog = true;
        });
    },

    getColor(status) {
      const result = this.searchableTags.find(({ name }) => name === status);
      if (result) {
        return result.color;
      } else {
        return "gray";
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }
    },
    headersForLocalStorage(){
      if (
          this.ordersHeaders &&
          this.ordersHeaders.length == this.headers.length
      ) {

        this.headers = JSON.parse(JSON.stringify(this.ordersHeaders));
      } else {
        this.$store.commit("setOrderHeaders", this.headers);
      }
    }
  },

  async mounted() {
    this.loading = true;
    this.$store.commit("setSearchTerm", "Órdenes");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    //GET HEADERS FROM LOCAL STORAGE
   this.headersForLocalStorage();

    await this.$binding(
      "cities",
      db
        .collection("cities")
        .where("active", "==", true)
        .where("deleted", "==", false)
        .orderBy("name", "asc")
    );
    // await Promise.all([cities]);

    // calculates the order time since the customer placed it
    // runs every 3 seconds
    // time property is not reactive :(
    setInterval(() => {
      this.orders.forEach((order) => {
        order.timeFromPlacedStatus = Math.round(
          (Date.now() - order.progressPlacedAt.toDate()) / 1000 / 60
        );
      });
    }, 3000);

    this.loading = false;
    this.getData();
  },
  destroyed() {
    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
};
</script>
<style lang="scss" scoped>
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@import "@/_responsive.scss";

@include responsive(mobile) {
  .table-mobile-r {
    max-width: 100vw !important;
    padding: 0px;
    margin: 0px;
    min-width: 100vw !important;
    width: 100vw !important;
  }
  .v-data-table table {
    max-width: 100vw !important;
    min-width: 100vw !important;
    width: 100vw !important;
    border-spacing: 0;
    background-color: aqua !important;
  }
  .v-data-table__mobile-table-row {
    max-width: 100vw !important;
    min-width: 100vw !important;
    width: 100vw !important;
    padding: 0px;
    margin: 0px;
  }

  tbody {
    width: 100vw !important;
    max-width: 100vw !important;
    padding: 0px;
    margin: 0px;
    min-width: 100vw !important;
    flex-direction: column;
  }
  .v-data-table {
    max-width: 100vw !important;
  }
}
</style>
